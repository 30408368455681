.start-date-upload input {
    background: transparent;
    border: none;
    outline: none;
    color: #94A3B8;
    font-size: 16px;

    ::placeholder {
        font-weight: 600;
    }
}

.custom-input {
    display: flex;
    align-items: center;
    border: 1px solid #E2E8F0;
    border-radius: 8px;
    padding: 8px;
    background-color: #000;
    color: #fff;
    cursor: pointer;
    padding-top: 11px;
    padding-bottom: 11px;
}
  
.custom-input input {
    border: none;
    background: transparent;
    outline: none;
    color: #fff;
    cursor: pointer;
    padding-left: 8px;
}
  
.calendar-icon {
    color: #fff;
}

.custom-input-main {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.date-text {
    color:#94A3B8;
}
  
.start-date-upload-scanned {
    width: 50%;
}

.start-date-upload-scanned input {
    width: 100%;
}

.start-date-upload-scanned .react-datepicker-wrapper{
    width: 100%;
}

.start-date-upload .react-datepicker-wrapper{
    width: 50%;
}

.start-date-upload-scanned .react-datepicker,
.start-date-upload-scanned .react-datepicker__header,
.start-date-upload .react-datepicker,
.start-date-upload .react-datepicker__header {
    background-color: #000;
    border: none;
}

.start-date-upload-scanned .react-datepicker__day,
.start-date-upload-scanned .react-datepicker__day-name,
.start-date-upload-scanned .react-datepicker__current-month,
.start-date-upload .react-datepicker__day,
.start-date-upload .react-datepicker__day-name,
.start-date-upload .react-datepicker__current-month {
    color: #F8FAFC;
    border: none;
}

.start-date-upload-scanned .react-datepicker__day:hover,
.start-date-upload .react-datepicker__day:hover {
    color: #000;
}
.start-date-upload-scanned .react-datepicker__triangle,
.start-date-upload .react-datepicker__triangle {
    display: none;
}
.start-date-upload-scanned .react-datepicker__day--disabled,
.start-date-upload .react-datepicker__day--disabled {
    color: #94A3B8;
    cursor: no-drop;
}
.start-date-upload-scanned .react-datepicker__day--disabled:hover,
.start-date-upload .react-datepicker__day--disabled:hover {
    background-color: #94A3B8;
}

.tiff-img canvas {
    width: 50px;
    height: 50px;
    border-radius: 8px;
  }

.upload-tiff-img canvas {
    width: 50px;
    height: 50px;
    border-radius: 8px;
  }

.preview-tiff-img canvas {
    height:auto;
    width:100%;
    margin-bottom: 8px;
    object-fit:contain;
}

.uploading-tiff-img canvas {
    width: 50px;
    height: 50px;
    border-radius: 8px;
}
